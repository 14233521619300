<template>
  <div class="contact_us">
    <span class="contact_span">联系我们</span>
    <div class="contact_con">
      <img class="contact_img" src="../../../assets/image/pc/r_kf.svg"/>
      <div class="contact_infor">
        <span class="contact_char_2">荷云采客服电话</span>
        <span class="contact_char_1">{{ configForm.hotline }}</span>
        <!-- <span class="contact_char">{{ configForm.hotline }}</span> -->
        <!-- <span class="contact_char_1">{{ configForm.userName }}:{{ configForm.phone }}</span> -->
      </div>
      <div class="contact_infor">
        <span class="contact_char_2">工作时间</span>
        <span class="contact_char_1">{{ configForm.workTime }}</span>
      </div>
      <div class="contact_infor">
        <span class="contact_char_3">工作地址</span>
        <span class="contact_char_1">{{ configForm.workAddress }}</span>
      </div>
      <div class="contact_infor">
        <span class="contact_char_4">湖南CA服务热线</span>
        <span class="contact_char_1">{{ configForm.hncaPhone }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact-card",
  data() {
    return {
      configForm: {}
    }
  },
  created() {
    /*初始化卡片*/
    this.getConfigs(203);
  }, methods: {
    getConfigs: function (id) {
      var that = this;
      this.$http.get(that.base + "/content/getCall.do", {
        params: {
          id: id
        }
      }).then(function (res) {
        var data = res.data.result;
        if (data) {
          that.configForm = data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
  }
}
</script>
<style scoped lang="css" src="../../../assets/commoon/css/common.css"></style>
