<template>
  <div class="tool_con">
    <router-link v-for="(item,index) in introduction.children" :key="'item'+index" class="tool_a"
                 :to="{'path': '/help', 'query': {'index': index,'itemId':item.id}}">
      <img class="tool_icon" :src="require('../../../assets/image/pc/t_icon_'+(index+1)+'.svg')"/><span
        class="tool_span">{{ item.categoryTitle }}</span>
      <img class="tool_arrow" src="../../../assets/image/pc/arrow_1.svg">
    </router-link>
  </div>
</template>

<script>
export default {
  name: "toolbar",
  data() {
    return {
      introduction: [],
    }
  },
  methods: {
    /*查询栏目及子栏目列表*/
    selectCategory: function (modelId, name) {
      let that = this;
      this.$http.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[name] = data.data.result;
      }).catch(function (error) {

      })
    },
  },
  created() {
    /*初始化新手入门*/
    this.selectCategory("introduction", "introduction");
  }
}
</script>

<style scoped lang="css" src="../../../assets/commoon/css/common.css"></style>

